import { Link as RouterLink } from 'react-router-dom'
import { RACE_TEMPLATES } from './RaceTemplates'

import { Link, Box } from '@chakra-ui/react'

export default function Races () {
  return RACE_TEMPLATES.map((race) => (
    <Box key={race.id}>
      <Link to={`/races/${race.id}`} as={RouterLink}>{race.name}</Link>
    </Box>
  ))
}
