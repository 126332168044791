import Races from './Races'
import { PinnedMountain } from './Mountain'
import { Link as RouterLink } from 'react-router-dom'

import { Center, VStack, Heading, Spacer, Link, Divider } from '@chakra-ui/react'

function Home () {
  return (
    <>
      <Center>
        <VStack m={4}>
          <Heading>Race Tracker</Heading>
          <Spacer py={4} />
          <Races />
          <Spacer py={14} />
          <Divider color='green.400' />
          <Link to='/settings' as={RouterLink}>Settings</Link>
        </VStack>
        <PinnedMountain />
      </Center>
    </>
  )
}
export default Home
