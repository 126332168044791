import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import WayPoint from './WayPoint'
import WayPointAdder from './WayPointAdder'
import ExpectedPace from './ExpectedPace'
import { ElevationBoxMountain, templateMountain } from './Mountain'
import { store, recover } from './LocalStorageHelper'
import { RACE_TEMPLATES } from './RaceTemplates'
import { parseTime, timeToMinutes, displayPace, displayPaceToPace, displayTime, minutesToTime } from './Helpers'
import { Heading, Flex, Box, Text, Center, Spacer } from '@chakra-ui/react'

const RACE_STORAGE_KEY_PREFIX = 'racetracker.racestate'

const BLANK_TIME = '--:--'

function raceStorageKey (raceId) {
  return RACE_STORAGE_KEY_PREFIX + '.' + raceId
}

function initRaceState (raceId) {
  const template = _.find(RACE_TEMPLATES, { id: raceId })
  const initialRaceState = { ...template }
  initialRaceState.pace = '12:00'
  initialRaceState.elevation = template.elevation || templateMountain
  initialRaceState.wayPoints = template
    ? _.map(template.wayPoints, (wayPoint, i, wayPoints) => {
      let time = BLANK_TIME
      if (i === 0 && template.startTime) {
        time = template.startTime 
      }
      return {
        name: wayPoint.name,
        pace: '',
        time: time,
        distance: wayPoint.distance,
        userDefined: false,
        isFirst: false,
        isLast: false
      }
    })
    : []

  return initialRaceState
}

function recoverOrInitRaceState (raceId) {
  return recover(raceStorageKey(raceId)) || initRaceState(raceId)
}

const Race = () => {
  const params = useParams()
  const [raceState, setRaceState] = useState(recalcRaceState(recoverOrInitRaceState(params.raceId)))

  useEffect(() => {
    store(raceStorageKey(params.raceId), raceState)
  }, [raceState])

  function calculateRaceTime () {
    const firstWayPoint = _.first(raceState.wayPoints)
    const lastWayPoint = _.last(raceState.wayPoints)
    if (firstWayPoint && lastWayPoint && firstWayPoint.time && lastWayPoint.time) {
      const startTimeInMinutes = timeToMinutes(parseTime(firstWayPoint.time))
      const finishTimeInMinutes = timeToMinutes(parseTime(lastWayPoint.time))
      const totalElapstedInMinutes = finishTimeInMinutes - startTimeInMinutes
      return displayTime(minutesToTime(totalElapstedInMinutes))
    } else {
      return 'N/A'
    }
  }

  function recalcRaceState (oldState) {
    const state = { ...oldState }

    // there is nothing to do on empty waypoints array
    if (state.wayPoints.length === 0) { return state }

    // sort by distance
    state.wayPoints = _.sortBy(state.wayPoints, 'distance')

    state.wayPoints.forEach((wayPoint, i, wayPoints) => {
      if (i === 0) {
        wayPoint.isFirst = true
        wayPoint.isLast = false
      } else if (i === wayPoints.length - 1) {
        wayPoint.isFirst = false
        wayPoint.isLast = true
      } else {
        wayPoint.isFirst = false
        wayPoint.isLast = false
      }
    })

    // recalculate distanceToNext
    state.wayPoints.forEach((wayPoint, i, wayPoints) => {
      const nextWayPoint = wayPoints[i + 1]
      wayPoint.distanceToNext = nextWayPoint ? nextWayPoint.distance - wayPoint.distance : null
    })

    // recalculate visited and last visited aid station
    state.wayPoints.forEach((wayPoint) => {
      wayPoint.visited = false
      wayPoint.lastVisited = false
    })

    let visited = false
    state.wayPoints.slice().reverse().forEach((wayPoint, i, wayPoints) => {
      if (!visited && wayPoint.userDefined) wayPoint.lastVisited = true
      visited = visited || wayPoint.userDefined
      wayPoint.visited = visited
    })

    state.wayPoints.forEach((wayPoint, i, wayPoints) => {
      wayPoint.pace = state.pace
      if (i === 0) return // we have nothing to do on the first way point

      if (wayPoint.userDefined) {
        // waypoint is user defined, back calcualte pace based on time
        const time = parseTime(wayPoint.time)
        const lastTime = parseTime(wayPoints[i - 1].time)
        const elapsedTimeInMinutes = timeToMinutes(time) - timeToMinutes(lastTime)
        const pace = elapsedTimeInMinutes / wayPoints[i - 1].distanceToNext
        if (pace) { wayPoints[i - 1].pace = displayPace(pace) }
      } else {
        // waypoint is not user defined, guess a time based on pace
        const lastPace = displayPaceToPace(state.pace)
        const elapsedTime = wayPoints[i - 1].distanceToNext * lastPace // in minutes
        const lastStationMinutes = timeToMinutes(parseTime(wayPoints[i - 1].time))
        const stationTimeInMinutes = lastStationMinutes + elapsedTime
        if (stationTimeInMinutes) { wayPoint.time = displayTime(minutesToTime(stationTimeInMinutes)) }
      }
    })
    return state
  }

  function handlePaceChange (newPace) {
    const state = { ...raceState }
    state.pace = newPace
    setRaceState(recalcRaceState(state))
  }

  function handleWayPointTimeChange (index, nextValue) {
    setRaceState((prevState) => {
      const state = { ...prevState }
      const wayPoint = state.wayPoints[index]
      wayPoint.time = nextValue
      wayPoint.userDefined = true
      return recalcRaceState(state)
    })
  }

  function handleWayPointBlur (index, nextValue) {
    setRaceState((prevState) => {
      const state = { ...prevState }
      const wayPoint = state.wayPoints[index]
      wayPoint.time = nextValue
      if (wayPoint.time === '--:--') { wayPoint.userDefined = false }
      return recalcRaceState(state)
    })
  }

  function insertWayPoint (wayPoint) {
    const state = { ...raceState }
    state.wayPoints = [...state.wayPoints, wayPoint] // recalcRaceState will resort
    setRaceState(recalcRaceState(state))
  }

  function handleWayPointRemove (removeIndex) {
    const state = { ...raceState }
    state.wayPoints.splice(removeIndex, 1)
    setRaceState(recalcRaceState(state))
  }

  return (
    <Flex direction='column' minH='100vh'>
      <Center py={4}>
        <Heading>{raceState.name}</Heading>
      </Center>
      <ExpectedPace pace={raceState.pace} onPaceChange={handlePaceChange} />,
      <Center>
        <Box minW='sm'>
          {raceState.wayPoints.map((wayPoint, i) =>
            <WayPoint
              index={i}
              key={i}
              onChange={handleWayPointTimeChange}
              onBlur={handleWayPointBlur}
              handleWayPointRemove={handleWayPointRemove}
              elevationData={raceState.elevation}
              {...wayPoint}
            />
          )}
        </Box>
      </Center>

      <Center>
        <Text>
          Total race time: {calculateRaceTime()}
        </Text>
      </Center>

      <WayPointAdder insertWayPointCallBack={insertWayPoint} />
      <Spacer />
      <ElevationBoxMountain elevationData={raceState.elevation} height={[32, 64, 80]} />
    </Flex>
  )
}
export default Race
