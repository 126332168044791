import { Editable, EditablePreview, EditableInput, Center, Text, Input } from '@chakra-ui/react'

function ExpectedPace ({ pace, onPaceChange }) {
  function handlePaceChange (nextValue) {
    onPaceChange(nextValue)
  }

  return (
    <Center>
      <Center p={2}>
        <Text>Expected pace:</Text>
      </Center>
      <Editable onChange={handlePaceChange} value={pace}>
        <EditablePreview />
        <Input htmlSize={4} as={EditableInput} />
      </Editable>
    </Center>
  )
}

export default ExpectedPace
