import { useRef } from 'react'
import { Flex, Heading, VStack, Button, Input } from '@chakra-ui/react'

function WayPointAdder ({ insertWayPointCallBack }) {
  function handleAddWayPoint () {
    const newWayPoint = {
      name: 'Custom Waypoint',
      distance: parseFloat(distanceRef.current.value),
      time: timeRef.current.value,
      userDefined: true,
      custom: true
    }
    insertWayPointCallBack(newWayPoint)
    distanceRef.current.value = ''
    timeRef.current.value = ''
  }

  function handleKeyPress (event) {
    if (event.key === 'Enter') {
      handleAddWayPoint()
    }
  }

  const distanceRef = useRef()
  const timeRef = useRef()

  return (
    <VStack pt={4}>
      <Heading as='h6' size='md'>Custom WayPoint</Heading>
      <Flex>
        <Input m={2} focusBorderColor='green.200' onKeyPress={handleKeyPress} ref={distanceRef} placeholder='distance' />
        <Input m={2} onKeyPress={handleKeyPress} ref={timeRef} placeholder='time' />
        <Button m={2} colorScheme='green' onClick={handleAddWayPoint}>Add</Button>
      </Flex>
    </VStack>
  )
}

export default WayPointAdder
