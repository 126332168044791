import { useRef } from 'react'
import { Trash } from 'react-bootstrap-icons'

import { Text, Flex, Box, useToken, useDimensions } from '@chakra-ui/react'

import WayPointInput from './WayPointInput'
import { CutoffElevationBoxMountain } from './Mountain'

function BoxLine ({ isFirst, isLast, lastVisited, doCircle, visited, ...props }) {
  const [green400, green100] = useToken('colors', ['green.400', 'green.100'])
  const boxRef = useRef()
  const dims = useDimensions(boxRef, true)

  const w = dims ? dims.borderBox.width : 0
  const h = dims ? dims.borderBox.height : 0

  let topLineColor
  let bottomLineColor
  if (lastVisited) {
    if (doCircle) {
      topLineColor = green400
      bottomLineColor = green100
    } else {
      topLineColor = green100
      bottomLineColor = green100
    }
  } else {
    topLineColor = visited ? green400 : green100
    bottomLineColor = topLineColor
  }
  const circleColor = topLineColor

  return (
    <Box ref={boxRef} {...props}>
      <svg position='relative' width={w} height={h} left='0' top='0'>
        {!isFirst && <path stroke={topLineColor} strokeWidth='5' d={`M ${w / 2} 0 v ${h / 2}`} />}
        {!isLast && <path stroke={bottomLineColor} strokeWidth='5' d={`M ${w / 2} ${h / 2} v ${h / 2}`} />}
        {doCircle && <circle fill={circleColor} cx={w / 2} cy={h / 2} r={6} />}
      </svg>
    </Box>
  )
}

const WayPoint = ({
  index,
  name,
  time,
  pace,
  distance,
  distanceToNext,
  userDefined,
  custom,
  onChange,
  onBlur,
  handleWayPointRemove,
  isFirst,
  isLast,
  visited,
  lastVisited,
  elevationData
}) => {
  return (
    <Box>
      <Flex align='center'>
        <BoxLine me={5} h={20} w={5} isFirst={isFirst} isLast={isLast} lastVisited={lastVisited} visited={visited} doCircle />
        <Text>Mile {distance} - {name} at</Text>
        <Box px={2}>
          <WayPointInput htmlSize={8} index={index} time={time} onChange={onChange} onBlur={onBlur} userEntered={userDefined} />
        </Box>
        {custom && <button className='btn' onClick={() => handleWayPointRemove(index)}><Trash /></button>}
      </Flex>
      {distanceToNext &&
        <Flex align='center'>
          <BoxLine me={5} h={20} w={5} visited={visited} lastVisited={lastVisited} />
          <CutoffElevationBoxMountain elevationData={elevationData} startLocation={distance} endLocation={distance + distanceToNext} height={[10]} width={28}/>
          <Text ml={4}>{distanceToNext.toFixed(2)} mi at {pace} min/mi</Text>
        </Flex>}
    </Box>
  )
}
export default WayPoint
