import _ from 'lodash'
import { useRef } from 'react'
import useWindowDimensions from './WindowDimensions'
import { Box, useDimensions, useToken } from '@chakra-ui/react'

export const templateMountain = {
  elevation: [
    [0.166, 0.5], [0.33, 0], [0.5, 0.8], [0.666, 0.2], [0.8, 0.6]
  ]
}

export function Mountain ({ w, h, points }) {
  const [green400] = useToken('colors', ['green.400'])
  const peaks = points.map(
    (point) => {
      const scaledY = ((point[1] - 0.5) * 0.9) + 0.5
      return [w * point[0], h - (h * scaledY)]
    }
  )
  const peaksPath = peaks.reduce((previous, current) => {
    return previous + ` L ${current[0]} ${current[1]}`
  }, '')

  const path = `M 0 ${h} ${peaksPath} ${w} ${h} Z`

  return (
    <svg postion='relative' width={w} height={h} left='0' top='0'>
      <path fill={green400} strokeLinejoin='round' stroke={green400} strokeWidth='0' d={path} />
    </svg>
  )
}

export function PinnedMountain ({ heightRatio = 0.4 }) {
  const dims = useWindowDimensions()
  const w = dims.width
  const h = dims.height * heightRatio
  return (
    <Box zIndex={-100} pos='fixed' bottom='0' left='0' width={w} height={h}>
      <Mountain w={w} h={h} points={templateMountain.elevation} />
    </Box>
  )
}

export function BoxMountain ({ points, ...props }) {
  const boxRef = useRef()
  const dims = useDimensions(boxRef, true)

  const w = dims ? dims.borderBox.width : 0
  const h = dims ? dims.borderBox.height : 0

  return (
    <Box ref={boxRef} {...props}>
      <Mountain w={w} h={h} points={points || templateMountain.elevation} />
    </Box>
  )
}
export function ElevationBoxMountain ({ elevationData, ...props }) {
  return (<BoxMountain points={elevationData.elevation} {...props} />)
}

export function CutoffElevationBoxMountain ({ startLocation, endLocation, elevationData, ...props }) {
  // _.sortedIndexBy(array, value, [iteratee=_.identity])
  const totalDistance = elevationData.totalDistance
  // console.log(`startLocation ${startLocation} endLocation ${endLocation} total distance: ${totalDistance} `)
  const startLocationByPercentage = startLocation / totalDistance
  const endLocationByPercentage = endLocation / totalDistance
  const startPointIndex = _.sortedIndexBy(elevationData.elevation, [startLocationByPercentage, 10], (point) => point[0])
  const endPointIndex = _.sortedIndexBy(elevationData.elevation, [endLocationByPercentage, 10], (point) => point[0])

  if (startPointIndex > elevationData.elevation.length) return false

  const startPercentage = elevationData.elevation[startPointIndex][0]
  const endPercentage = elevationData.elevation[endPointIndex][0]
  const percentSpan = endPercentage - startPercentage

  const points = elevationData.elevation.slice(startPointIndex, endPointIndex).map((point) => [(point[0] - startPercentage) / percentSpan, point[1]])

  return (<BoxMountain points={points} {...props} />)
}
