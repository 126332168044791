import './App.css'

import Main from './Main'
import Home from './Home'
import Race from './Race'
import Races from './Races'
import Settings from './Settings'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { ChakraProvider, extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

const theme = extendTheme(
  {
    components: {
      Input: {
        defaultProps: { focusBorderColor: 'green.200' }
      }
    }
  },
  withDefaultColorScheme({
    colorScheme: 'green',
    components: ['Button']
  })
)

function App () {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />}>
            <Route index element={<Home />} />
            <Route path='races'>
              <Route path=':raceId' element={<Race />} />
              <Route index element={<Races />} />
            </Route>
            <Route path='settings' element={<Settings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  )
}

export default App
