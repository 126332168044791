import { PinnedMountain } from './Mountain'
import { Box, VStack, Heading, Button, Spacer, useToast, Text } from '@chakra-ui/react'
import { clear } from './LocalStorageHelper'
import { Trash } from 'react-bootstrap-icons'

function CustomToast (props) {
  return (
    <Box
      background='green.100'
      w='auto'
      textAlign='center'
      py={2}
      rounded='lg'
    >
      {props.children}
    </Box>
  )
}

function Settings () {
  const toast = useToast()

  function handleClearLocalState (event) {
    clear()
    toast({
      duration: 2000,
      title: 'Local State Cleared',
      position: 'bottom',
      render: () => (<CustomToast><Text>Local Storage Cleared</Text></CustomToast>)
    })
  }

  return (
    <VStack m={4}>
      <Heading>Settings</Heading>
      <Text fontSize='xs'>The only limit is you.</Text>
      <Spacer py={4} />
      <Button size='sm' leftIcon={<Trash />} onClick={handleClearLocalState}>Clear Local State</Button>
      <PinnedMountain />
    </VStack>
  )
}
export default Settings
