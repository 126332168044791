const localStorage = window.localStorage
const SCHEMA_VERSION_KEY = 'racetracker.schemaVersion'
const SCHEMA_VERSION = '2'

function store (key, value) {
  localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION)
  localStorage.setItem(key, JSON.stringify(value))
}

function recover (key) {
  if (SCHEMA_VERSION !== localStorage.getItem(SCHEMA_VERSION_KEY)) {
    clear()
    return
  }
  return JSON.parse(localStorage.getItem(key))
}

function clear () {
  localStorage.clear()
}

export { store, recover, clear }
