import { Editable, EditablePreview, EditableInput, Input } from '@chakra-ui/react'
function WayPointInput ({ index, time, onChange, onBlur, userEntered }) {
  function handleOnChange (nextValue) {
    onChange(index, nextValue)
  }

  function handleOnSubmit (nextValue) {
    if (nextValue === '') {
      nextValue = '--:--'
    }
    onBlur(index, nextValue)
  }

  return (
    <Editable onChange={handleOnChange} onSubmit={handleOnSubmit} value={time}>
      <EditablePreview />
      <Input htmlSize={8} as={EditableInput} />
    </Editable>
  )
}

export default WayPointInput
