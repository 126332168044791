function parseTime (time) {
  return { hours: parseInt(time.slice(0, 2)), minutes: parseInt(time.slice(-2)) }
}

function timeToMinutes (time) {
  return time.hours * 60 + time.minutes
}

function minutesToTime (minutes) {
  return { hours: Math.floor(minutes / 60), minutes: Math.floor(minutes % 60) }
}

function displayPace (pace) {
  return Math.floor(pace) + ':' + ((pace % 1) * 60).toString().padStart(2, '0').slice(0, 2)
}

function displayTime (time) {
  return time.hours + ':' + time.minutes.toString().padStart(2, '0')
}

function displayPaceToPace (displayPace) {
  return parseInt(displayPace.slice(0, 2)) + (parseInt(displayPace.slice(-2)) / 60)
}

export { parseTime, timeToMinutes, minutesToTime, displayPace, displayTime, displayPaceToPace }
