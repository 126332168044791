import wyeastElevationData from './elevations/wyeast-svg.json'
import leadvilleElevationData from './elevations/leadville-svg.json'

const RACE_TEMPLATES = [
  {
    id: 'leadville',
    name: "Leadville",
    startTime: "04:00",
    elevation: leadvilleElevationData,
    wayPoints: [
      { name: 'Start', distance: 0 },
      { name: 'Mayqueen 1', distance: 12.6 },
      { name: 'Outward Bound', distance: 23.5 },
      { name: 'Twin Lakes Village', distance: 37.9 },
      { name: 'Twin Lakes Village', distance: 62.5 },
      { name: 'Half Pipe', distance: 71.1 },
      { name: 'Outward Bound', distance: 76.9 },
      { name: 'Mayqueen', distance: 87.8 },
      { name: 'Finish', distance: 99.2 }
    ]
  },
  {
    id: 'wyeast_wonder_elena',
    name: "Wy'east Wonder - Elena",
    elevation: wyeastElevationData,
    wayPoints: [
      { name: 'Start', distance: 0 },
      { name: 'Aquaduct 1', distance: 10.2 },
      { name: 'Dog River', distance: 15.2 },
      { name: 'Aquaduct 2', distance: 20.2 },
      { name: 'Bottle Prairie 1', distance: 24.5 },
      { name: 'Bottle Prairie 2', distance: 30.9 },
      { name: 'Aquaduct 3', distance: 39.8 },
      { name: 'Gibson Prairie', distance: 44.5 },
      { name: 'Finish', distance: 49.9 }
    ]
  },
  {
    id: 'wyeast_wonder_joy',
    name: "Wy'east Wonder - Joy",
    elevation: wyeastElevationData,
    wayPoints: [
      { name: 'Start', distance: 0 },
      { name: 'Aquaduct 1', distance: 10.2 },
      { name: 'Dog River', distance: 15.2 },
      { name: 'Aquaduct 2', distance: 20.2 },
      { name: 'Bottle Prairie 1', distance: 24.5 },
      { name: 'Bottle Prairie 2', distance: 30.9 },
      { name: 'Aquaduct 3', distance: 39.8 },
      { name: 'Gibson Prairie', distance: 44.5 },
      { name: 'Finish', distance: 49.9 }
    ]
  },
  {
    id: 'wyeast_wonder_yonas',
    name: "Wy'east Wonder - Yonas",
    elevation: wyeastElevationData,
    wayPoints: [
      { name: 'Start', distance: 0 },
      { name: 'Aquaduct 1', distance: 10.2 },
      { name: 'Dog River', distance: 15.2 },
      { name: 'Aquaduct 2', distance: 20.2 },
      { name: 'Bottle Prairie 1', distance: 24.5 },
      { name: 'Bottle Prairie 2', distance: 30.9 },
      { name: 'Aquaduct 3', distance: 39.8 },
      { name: 'Gibson Prairie', distance: 44.5 },
      { name: 'Finish', distance: 49.9 }
    ]
  },
  {
    id: 'blank_race',
    name: 'An Empty Race',
    wayPoints: []
  }
]

export { RACE_TEMPLATES }
